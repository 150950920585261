<template>
  <div class="row pt-3 pl-7 pr-7">
    <div class="col-xl-12">
      <div class="timeline timeline-4">
        <div class="timeline-bar"></div>
        <div class="timeline-items pb-10">
          <template v-for="(item,index) in updateList">
            <div :key="item.recordId">
              <div class="timeline-item timeline-item-left" v-if="(index%2)==0">
                <div class="timeline-badge">
                  <div class="bg-danger"></div>
                </div>
                <div class="timeline-label">
                  <span class="text-primary font-weight-bolder">版本:{{item.no}}&emsp;时间:{{item.createdDate}}</span>
                </div>
                <div class="timeline-content">
                  <span v-html="item.content"></span>
                </div>
              </div>
              <div class="timeline-item timeline-item-right" v-else>
                <div class="timeline-badge">
                  <div class="bg-success"></div>
                </div>
                <div class="timeline-label text-primary">
                  <span class="text-primary font-weight-bolder">版本:{{item.no}}&emsp;时间:{{item.createdDate}}</span>
                </div>
                <div class="timeline-content">
                  <span v-html="item.content"></span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    user: {
      type: Object
    }
  },
  mounted() {
    this.$emit('send', "2");
    this.getUpdateList();
  },
  data(){
    return{
      updateList: []
    }
  },
  methods:{
    getUpdateList(){
      const update ={};
      this.$axios.fetchPost('kybOa/getUpdateList', update).then((data) => {
        if (data && data.data) {
          this.updateList = data.data;
        }
      }).catch(err => {console.log(err)})
    }
  }
}
</script>